import Image from 'next/image';
import Script from 'next/script';
import Link from 'next/link';

// Assets
import { IMAGES } from '@Assets/images';

// Types
import { IAppInfo } from '@Src/types/app';

import { ENV } from '@Src/constant';

export default function DefaultFooter(props: IAppInfo) {
    const { email } = props;
    const title = 'Trang tổng hợp những sách nói hay, truyện audio, mới nhất, chất lượng cao và hoàn toàn miễn phí.';

    return (
        <>
            <div className="shadow py-8 bg-gray-50">
                <div className="wrapper-container container px-4 xl:px-0 mx-auto grid grid-cols-1 md:grid-cols-4">
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-row space-x-2 items-start">
                            <Image title={title || ''} width={60} height={60} src={IMAGES.LOGO} alt={title || ''} />
                            <label className="leading-[1.5rem] text-justify">{title}</label>
                        </div>
                        <label className="">Email: {email || 'nghesachnoi.ads@gmail.com'}</label>
                    </div>
                    <div className="mt-4 lg:mt-0 lg:pl-10 leading-[2rem]">
                        <ul>
                            <li>
                                <label className="font-medium">Thể loại nổi bật</label>
                            </li>
                            <li>
                                <Link
                                    title="Sách nói nuôi dậy con"
                                    className="hover:text-red-700"
                                    href="/nuoi-day-con.html"
                                >
                                    Sách nói nuôi dậy con
                                </Link>
                            </li>
                            <li>
                                <Link
                                    title="Sách nói kỹ năng sống"
                                    className="hover:text-red-700"
                                    href="/ky-nang-song.html"
                                >
                                    Sách nói kỹ năng sống
                                </Link>
                            </li>
                            <li>
                                <Link
                                    title="Sách nói Tâm linh - Tôn giáo"
                                    className="hover:text-red-700"
                                    href="/tam-linh-ton-giao.html"
                                >
                                    Sách nói Tâm linh - Tôn giáo
                                </Link>
                            </li>
                            <li>
                                <Link title="Thể loại sách nói" href="/the-loai.html" className="hover:text-red-700">
                                    Tất cả thể loại
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-4 lg:mt-0 lg:pl-10 leading-[2rem]">
                        <ul>
                            <li>
                                <label className="font-medium">Tác giả nổi bật</label>
                            </li>
                            <li>
                                <Link
                                    title="Tác giả Nguyên Phong"
                                    className="hover:text-red-700"
                                    href="/author/nguyen-phong.html"
                                >
                                    Nguyên Phong
                                </Link>
                            </li>
                            <li>
                                <Link
                                    title="Tác giả Nguyễn Nhật Ánh"
                                    className="hover:text-red-700"
                                    href="/author/nguyen-nhat-anh.html"
                                >
                                    Nguyễn Nhật Ánh
                                </Link>
                            </li>
                            <li>
                                <Link
                                    title="Tác giả Thích Nhất Hạnh"
                                    className="hover:text-red-700"
                                    href="/author/thich-nhat-hanh.html"
                                >
                                    Thích Nhất Hạnh
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-4 lg:mt-0 lg:pl-10 leading-[2rem]">
                        <ul>
                            <li>
                                <Link title="Về Nghe Sách Nói" className="hover:text-red-700" href="/about.html">
                                    Về chúng tôi
                                </Link>
                            </li>
                            <li>
                                <Link
                                    title="Chính sách bảo mật"
                                    className="hover:text-red-700"
                                    href="/privacy-policy.html"
                                >
                                    Chính sách bảo mật
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {ENV !== 'development' ? (
                <>
                    <Script
                        async
                        src="https://www.googletagmanager.com/gtag/js?id=G-38BD87E80P"
                        strategy="afterInteractive"
                    />
                    <Script id="google-analytics" strategy="afterInteractive">
                        {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){window.dataLayer.push(arguments);}
                  gtag('js', new Date());
        
                  gtag('config', 'G-38BD87E80P');
                `}
                    </Script>
                    <Script
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8313191322291660"
                        crossOrigin="anonymous"
                        strategy="afterInteractive"
                    />
                </>
            ) : null}
        </>
    );
}
