import Head from 'next/head';
import { useRouter } from 'next/router';

// Types
import { IAppInfo } from '@Src/types/app';

// interface IDefaultHeadProps extends IApp {
//     [key]: Record<string, any>;
// }

// Constant
import { SITE_URL } from '@Src/constant';

// Tool check breadcrumb valid => https://search.google.com/test/rich-results/result
// Breadcrumb reference => https://developers.google.com/search/docs/appearance/structured-data/book
// Breadcrumb review & aggregateRating => https://developers.google.com/search/docs/appearance/structured-data/review-snippet

export default function DefaultHead(props: IAppInfo) {
    const { title, description, keyword, images = [], breadcrumbList, breadcrumbBook } = props;
    const router = useRouter();
    const asPath = router.asPath;
    const href = SITE_URL + asPath.replace('/?trang=1', '');

    // const json = {
    //     '@context': 'http://schema.org',
    //     '@type': 'NewsArticle',
    //     name: 'meovathay.vn',
    //     url: 'https://meovathay.vn',
    //     address: '',
    //     sameAs: [
    //         'https://www.facebook.com/meovathaymoingay1',
    //         'https://www.youtube.com/channel/UCYDyUZJ2T_2tPqwQtJH1EAA',
    //     ],
    // };

    return (
        <Head>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
            <meta name="theme-color" content="#ffffff" />
            <link rel="canonical" href={href} />
            <link rel="alternate" href={href} hrefLang="vi-vn" />
            {/*<link rel="alternate" media="only screen and (max-width: 640px)" href="https://m.nghesachnoi.com/" />*/}
            {/*<link rel="alternate" media="handheld" href="https://m.nghesachnoi.com/" />*/}
            <link rel="dns-prefetch" href="//www.google-analytics.com" />
            <link rel="dns-prefetch" href="//www.googletagmanager.com" />
            <link rel="preconnect" href="//www.google-analytics.com" />
            <link rel="preconnect" href="//www.googletagmanager.com" />
            {/*<meta content="news" itemProp="genre" name="medium" />*/}
            <meta content="vi-VN" itemProp="inLanguage" />
            <meta content="Nghe sách nói" itemProp="sourceOrganization" name="source" />
            <meta name="robots" content="index,follow" />
            <meta name="copyright" content="nghesachnoi.com" />
            <meta name="author" content="nghesachnoi.com" />

            {/* Facebook metadata */}
            <meta property="og:title" itemProp="headline" content={title} />
            <meta property="og:rich_attachment" content="true" />
            <meta property="og:type" content="website" />
            <meta property="og:description" itemProp="description" content={description} />
            {images.length ? (
                images.map((image) => {
                    return (
                        <meta key={`og:image-${image}`} property="og:image" itemProp="thumbnailUrl" content={image} />
                    );
                })
            ) : (
                <meta property="og:image" itemProp="thumbnailUrl" content="https://nghesachnoi.com/logo/114x114.png" />
            )}

            <meta property="og:url" itemProp="url" content={href} />
            <meta property="og:site_name" content="nghesachnoi.com" />

            {/* Twitter metadata */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={href} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:site" content="@nghesachnoi.com" />
            <meta name="twitter:creator" content="@nghesachnoi.com" />
            {images.length ? (
                images.map((image) => {
                    return <meta key={`twitter:image-${image}`} name="twitter:image" content={image} />;
                })
            ) : (
                <meta name="twitter:image" content="https://nghesachnoi.com/logo/114x114.png" />
            )}

            {/* Apple metadata */}
            <link rel="apple-touch-icon-precomposed" href="https://nghesachnoi.com/logo/72x72.png" sizes="72x72" />
            <link rel="apple-touch-icon-precomposed" href="https://nghesachnoi.com/logo/114x114.png" sizes="144x144" />
            <link rel="apple-touch-icon-precomposed" href="https://nghesachnoi.com/logo/57x57.png" sizes="57x57" />
            <link rel="apple-touch-icon-precomposed" href="https://nghesachnoi.com/logo/114x114.png" sizes="114x114" />
            <link rel="apple-touch-icon-precomposed" href="https://nghesachnoi.com/logo/114x114.png" sizes="1x1" />
            <link rel="nokia-touch-icon" href="https://nghesachnoi.com/logo/57x57.png" />

            {/* Schema metadata */}
            {breadcrumbList ? (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbList) }}
                />
            ) : null}

            {breadcrumbBook ? (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbBook) }}
                />
            ) : null}
        </Head>
    );
}
