import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Popover } from 'antd';
import classNames from 'classnames';

import { SvgIcon } from '@Components/svg-definition';

// Assets
import { IMAGES } from '@Assets/images';

// Constant
import { KEY_CODE } from '@Src/constant';

// Types
import { IAppInfo } from '@Src/types/app';

export default function DefaultHeader(props: IAppInfo) {
    const router = useRouter();
    const { title } = props;

    const [searchValue, setSearchValue] = useState(router.query?.q || '');
    const [isShowSearchMobile, setShowSearchMobile] = useState(false);
    const [isShowMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const handleStart = () => {
            // Hide menu when change to another route
            setShowMenu(false);
        };

        router.events.on('routeChangeStart', handleStart);

        return () => {
            router.events.off('routeChangeStart', handleStart);
        };
    }, [router]);

    useEffect(() => {
        if (typeof window !== undefined) {
            const body = document.getElementsByTagName('body');

            if (body && body.length) {
                if (isShowSearchMobile || isShowMenu) {
                    body[0].style.overflow = 'hidden';
                } else {
                    body[0].style.overflow = 'visible';
                }
            }
        }
    }, [isShowSearchMobile, isShowMenu]);

    const onSearch = (e) => {
        setSearchValue(e.target.value);
    };

    const onClickInputClear = () => {
        setSearchValue('');
    };

    const onInputKeyDown = (e) => {
        if (e.keyCode === KEY_CODE.ENTER) {
            router.push(`/search?q=${searchValue}`);

            setShowSearchMobile(false);
        }
    };

    const onClickClearSearch = () => {
        setSearchValue('');
    };

    const onClickSearchBackdrop = () => {
        setShowSearchMobile(false);
    };

    const audiobooksMenu = [
        {
            label: 'Tất cả thể loại',
            title: 'Tất cả thể loại',
            path: '/the-loai.html',
        },
        {
            label: 'Sách nói mới',
            title: 'Sách nói mới cập nhật',
            path: '/sach-noi-moi-nhat.html',
        },
        {
            label: 'Sách nói hay',
            title: 'Sách nói hay mới cập nhật',
            path: '/sach-noi-noi-bat.html',
        },
        {
            label: 'Danh Nhân',
            title: 'Sách nói về các Danh Nhân nổi tiếng',
            path: '/danh-nhan.html',
        },
        {
            label: 'Chứng Khoán',
            title: 'Sách nói về đầu tư chứng khoán',
            path: '/chung-khoan.html',
        },
    ];

    const authorsMenu = [
        {
            label: 'Nguyên Phong',
            title: 'Sách nói từ tác giả Nguyên Phong',
            path: '/author/nguyen-phong.html',
        },
        {
            label: 'Nguyễn Nhật Ánh',
            title: 'Sách nói từ tác giả Nguyễn Nhật Ánh',
            path: '/author/nguyen-nhat-anh.html',
        },
        {
            label: 'Thích Nhất Hạnh',
            title: 'Sách nói từ tác giả Thích Nhất Hạnh',
            path: '/author/thich-nhat-hanh.html',
        },
    ];

    const storiesMenu = [
        {
            label: 'Ngôn tình',
            title: 'Truyện ngôn tình',
            path: '/ngon-tinh.html',
        },
        {
            label: 'Nguyễn Nhật Ánh',
            title: 'Truyện của tác giả Nguyễn Nhật Ánh',
            path: '/author/nguyen-nhat-anh.html',
        },
        {
            label: 'Trinh thám',
            title: 'Truyện trinh thám',
            path: '/tieu-thuyet-trinh-tham.html',
        },
        {
            label: 'Truyện ngắn - Tản văn',
            title: 'Truyện ngắn - tản văn',
            path: '/truyen-ngan.html',
        },
    ];

    const audiobooksMenuContent = (
        <div className="">
            {audiobooksMenu.length
                ? audiobooksMenu.map((menu, key) => {
                      return (
                          <p className="flex p-0 m-0" key={menu.path}>
                              <Link
                                  className="text-base px-4 py-1 w-full hover:text-red-700 font-medium hover:bg-gray-50 rounded"
                                  title={menu.title}
                                  href={menu.path}
                              >
                                  {menu.label}
                              </Link>
                          </p>
                      );
                  })
                : null}
        </div>
    );

    const authorMenuContent = (
        <div className="">
            {authorsMenu.length
                ? authorsMenu.map((menu, key) => {
                      return (
                          <p className="flex p-0 m-0" key={menu.path}>
                              <Link
                                  className="text-base px-4 py-1 w-full hover:text-red-700 font-medium hover:bg-gray-50 rounded"
                                  title={menu.title}
                                  href={menu.path}
                              >
                                  {menu.label}
                              </Link>
                          </p>
                      );
                  })
                : null}
        </div>
    );

    const storyMenuContent = (
        <div className="">
            {storiesMenu.length
                ? storiesMenu.map((menu, key) => {
                      return (
                          <p className="flex p-0 m-0" key={menu.path}>
                              <Link
                                  className="text-base px-4 py-1 w-full hover:text-red-700 font-medium hover:bg-gray-50 rounded"
                                  title={menu.title}
                                  href={menu.path}
                              >
                                  {menu.label}
                              </Link>
                          </p>
                      );
                  })
                : null}
        </div>
    );

    const onClickMenu = () => {
        setShowMenu(true);
        setShowSearchMobile(false);
    };

    const onClickMenuBackdrop = () => {
        setShowMenu(false);
    };

    const renderMenuContents = () => {
        return (
            <div className="flex flex-col fixed top-16 left-0 right-0 bottom-0 z-10">
                <div className="relative flex flex-none flex-col p-4 bg-white min-h-[200px] border-t border-gray-200">
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center mb-2">
                            <h3 className="mb-0">Thể loại nổi bật</h3>
                            <Link
                                title="Thể loại sách nói"
                                href="/the-loai.html"
                                className="pl-2 text-sm text-blue-600"
                            >
                                [Tất cả thể loại]
                            </Link>
                        </div>
                        <ul className="space-y-2">
                            {audiobooksMenu.length
                                ? audiobooksMenu.map((audiobookMenu) => {
                                      if (audiobookMenu.path !== '/the-loai.html') {
                                          return (
                                              <li key={audiobookMenu.path}>
                                                  <Link title={audiobookMenu.title} href={audiobookMenu.path}>
                                                      {audiobookMenu.label}
                                                  </Link>
                                              </li>
                                          );
                                      }
                                  })
                                : null}
                        </ul>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center mb-2">
                            <h3 className="mb-0">Tác giả nổi bật</h3>
                        </div>
                        <ul className="space-y-2">
                            {authorsMenu.length
                                ? authorsMenu.map((authorMenu) => {
                                      return (
                                          <li key={authorMenu.path}>
                                              <Link title={authorMenu.title} href={authorMenu.path}>
                                                  {authorMenu.label}
                                              </Link>
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </div>
                </div>
                <div
                    className="flex flex-grow backdrop-brightness-50 backdrop-blur-sm"
                    onClick={onClickMenuBackdrop}
                ></div>
            </div>
        );
    };

    return (
        <div className="shadow sticky top-0 bg-white z-50">
            <div className="flex flex-row items-center wrapper-container container px-4 xl:px-0 mx-auto h-16 justify-between">
                <div className="flex flex-row flex-none items-center space-x-2 lg:space-x-0">
                    {isShowMenu ? (
                        <div onClick={() => setShowMenu(false)} className="lg:hidden p-1 hover:bg-gray-200 rounded">
                            <SvgIcon name="close" size={24} />
                        </div>
                    ) : (
                        <div onClick={onClickMenu} className="lg:hidden p-1 hover:bg-gray-200 rounded">
                            <SvgIcon name="menu" size={24} />
                        </div>
                    )}

                    <h1 className="m-0">
                        <Link title={title} className="flex flex-row items-center space-x-2 cursor-pointer" href={'/'}>
                            <Image title={title || ''} width={45} height={45} src={IMAGES.LOGO} alt={title || ''} />
                            <label className="font-medium w-[170px] hover:text-red-700 cursor-pointer">
                                Nghe Sách Nói
                            </label>
                        </Link>
                    </h1>
                </div>

                <div className="flex-grow items-center pr-4 lg:flex hidden">
                    <div className="flex flex-row text-sm w-full min-w-[250px] max-w-[500px] justify-between items-center border border-gray-200 hover:border-gray-500 pr-2 rounded-lg">
                        <input
                            onChange={onSearch}
                            onKeyDown={onInputKeyDown}
                            value={searchValue}
                            className="px-3 py-2 rounded-lg w-full"
                            title="Tìm kiếm sách, tác giả, thể loại, nhà xuất bản,..."
                            placeholder="Tìm kiếm sách, tác giả, thể loại, nhà xuất bản,..."
                        />
                        {searchValue ? (
                            <span key="input-close" onClick={onClickInputClear} className="cursor-pointer">
                                <SvgIcon name="close" size={20} />
                            </span>
                        ) : (
                            <span key="search-outlined" className="cursor-pointer">
                                <SvgIcon name="search-outlined" size={20} />
                            </span>
                        )}
                    </div>
                </div>

                {/* Desktop */}
                <div className="flex-row items-center lg:flex hidden">
                    <div className="relative flex flex-row items-center space-x-6 pr-4">
                        <Popover
                            placement="bottomLeft"
                            content={audiobooksMenuContent}
                            overlayInnerStyle={{ padding: `5px 0` }}
                            trigger="click"
                        >
                            <h2 className="flex items-center m-0 p-0 hover:text-red-700 space-x-1 cursor-pointer text-base">
                                <label className="cursor-pointer">Sách Nói</label>
                                <SvgIcon size={13} name="arrow-down" />
                            </h2>
                        </Popover>

                        <Popover
                            placement="bottomLeft"
                            content={authorMenuContent}
                            overlayInnerStyle={{ padding: `5px 0` }}
                            trigger="click"
                        >
                            <h2 className="flex items-center m-0 p-0 hover:text-red-700 space-x-1 cursor-pointer text-base">
                                <label className="cursor-pointer">Tác Giả</label>
                                <SvgIcon size={13} name="arrow-down" />
                            </h2>
                        </Popover>

                        {/*<Popover*/}
                        {/*    placement="bottomLeft"*/}
                        {/*    content={storyMenuContent}*/}
                        {/*    overlayInnerStyle={{ padding: `5px 0` }}*/}
                        {/*    trigger="click"*/}
                        {/*>*/}
                        {/*    <h2 className="flex items-center m-0 p-0 hover:text-red-700 space-x-1 cursor-pointer text-base">*/}
                        {/*        <label className="cursor-pointer">Truyện</label>*/}
                        {/*        <SvgIcon size={13} name="arrow-down" />*/}
                        {/*    </h2>*/}
                        {/*</Popover>*/}

                        <h2 className="flex items-center m-0 p-0 hover:text-red-700 cursor-pointer">
                            <Link title="Các bài viết về sách nói" href="/bai-viet.html" className="text-base">
                                Blog
                            </Link>
                        </h2>
                    </div>

                    <div className="flex w-0.5 h-[25px] bg-gray-200"></div>

                    <div className="flex flex-row pl-4 items-center space-x-2">
                        <Link
                            title="Tài khoản của bạn"
                            href="/account/profile"
                            className="flex p-3 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200"
                        >
                            <SvgIcon name="user" />
                        </Link>
                    </div>
                </div>

                {/* Mobile */}
                <div className="flex-row items-center lg:hidden">
                    <div className="flex flex-row items-center space-x-3">
                        <span
                            onClick={() => {
                                setShowSearchMobile(!isShowSearchMobile);
                                setShowMenu(false);
                            }}
                            className="cursor-pointer p-2.5 bg-gray-100 rounded-full"
                        >
                            <SvgIcon
                                name="search-outlined"
                                className={classNames({ 'text-blue-500': isShowSearchMobile })}
                                size={20}
                            />
                        </span>
                        <span>
                            <Link
                                title="Tài khoản của bạn"
                                href="/account/profile"
                                className="flex p-3 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200"
                            >
                                <SvgIcon name="user" className="text-black" size={16} />
                            </Link>
                        </span>
                    </div>
                </div>

                {isShowSearchMobile ? (
                    <div className="flex flex-col fixed top-16 left-0 right-0 bottom-0 z-10">
                        <div className="relative flex flex-none bg-white h-14 border-t border-gray-200">
                            <input
                                onChange={onSearch}
                                onKeyDown={onInputKeyDown}
                                value={searchValue}
                                className="px-3 py-2 rounded-xl h-full w-full"
                                title="Tìm kiếm sách, tác giả, thể loại, nhà xuất bản,..."
                                placeholder="Tìm kiếm sách, tác giả, thể loại, nhà xuất bản,..."
                            />
                            {searchValue ? (
                                <div
                                    onClick={onClickClearSearch}
                                    className="flex items-center justify-center cursor-pointer w-[30px] h-full"
                                >
                                    <SvgIcon name="close" size={16} />
                                </div>
                            ) : null}
                        </div>
                        <div
                            onClick={onClickSearchBackdrop}
                            className="flex flex-grow backdrop-brightness-50 backdrop-blur-sm"
                        ></div>
                    </div>
                ) : null}

                {/* Mobile */}
                {isShowMenu ? renderMenuContents() : null}
            </div>
        </div>
    );
}
